import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/solid";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { ArrowDownIcon } from "@heroicons/react/24/solid";
import { Bars2Icon } from "@heroicons/react/24/solid";
import { BugAntIcon } from "@heroicons/react/24/solid";
import { protectedResources } from "../../../authConfig";
import useFetchWithMsal from '../../../hooks/useFetchWithMsal';
import { useMsal } from '@azure/msal-react';
import Multiselect from 'multiselect-react-dropdown';
import Select, { components } from "react-select";
import { createClaimsTable } from '../../../utils/claimUtils';
import { AGENTES_IT, BACKEND_URL, GET_OPCIONES_BLOQUEADO_POR, PRIORIDADES_TICKETS, TIPOS_TICKETS, DEFAULT_TICKET, POST_ADJUNTOS_PATH, POST_TICKET_PATH } from '../../../constants';
import { InputFile } from './UploadFileComponent/InputFile';

export default function AddTaskModal({ addAssetModalSetting, handlePageUpdate, updateAssetData = null }) {
  const { instance } = useMsal();
  let activeAccount = instance.getActiveAccount();

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes,
  });

  const getLogginInfo = () => {
    const tokenClaims = createClaimsTable(activeAccount.idTokenClaims);
    let email = "";
    let name = "";
    let id = "";

    Object.keys(tokenClaims).map((key) => {
      if (tokenClaims[key][0] == "name") {
        name = tokenClaims[key][1];
      } else if (tokenClaims[key][0] == "oid") {
        id = tokenClaims[key][1];
      } else if (tokenClaims[key][0] == "email") {
        email = tokenClaims[key][1];
      }
    });

    return { 'currentEmail': email, 'currentName': name, 'currentId': id };
  }

  const tipitos = [
    { value: TIPOS_TICKETS.issue, label: TIPOS_TICKETS.issue, color: "#17469e", icon: <ClipboardDocumentCheckIcon className="inline mr-2 h-6 w-6 text-blue-800" aria-hidden="true" /> },
    { value: TIPOS_TICKETS.tarea, label: TIPOS_TICKETS.tarea, color: "#b72842", icon: <ClipboardDocumentListIcon className="inline mr-2 h-6 w-6 text-green-800" aria-hidden="true" /> },
    { value: TIPOS_TICKETS.bug, label: TIPOS_TICKETS.bug, color: "#b72842", icon: <BugAntIcon className="inline mr-2 h-6 w-6 text-red-800" aria-hidden="true" /> }
  ];

  const prioridades = [
    { value: PRIORIDADES_TICKETS.baja, label: PRIORIDADES_TICKETS.baja, color: "#17469e", icon: <ArrowDownIcon className="inline mr-2 h-6 w-6 text-green-700" aria-hidden="true" /> },
    { value: PRIORIDADES_TICKETS.media, label: PRIORIDADES_TICKETS.media, color: "#b72842", icon: <Bars2Icon className="inline mr-2 h-6 w-6 text-orange-400" aria-hidden="true" /> },
    { value: PRIORIDADES_TICKETS.alta, label: PRIORIDADES_TICKETS.alta, color: "#b72842", icon: <ArrowUpIcon className="mr-2 h-6 w-6 text-red-800 inline" aria-hidden="true" /> }
  ];

  const [ticket, setTicket] = useState(updateAssetData ? updateAssetData : DEFAULT_TICKET);


  const [selectedfile, SetSelectedFile] = useState([]);
  const [opcionesBloqueadoPor, setOpcionesBloqueadoPor] = useState([]);
  const [bloqueadoPor, setBloqueadoPor] = useState([]);
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const [tipo, setTipo] = useState(tipitos[0]);
  const [prioridad, setPrioridad] = useState(prioridades[0]);
  const [observadores, setObservadores] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [options, setOptions] = useState([]);

  // Fetching Data of All Assets
  const setCurrentDate = () => {
    let copy_asset = ticket;
    const dateObj = new Date();

    copy_asset['fechaCreacion'] = dateObj;
    setTicket({ ...ticket, ['fechaCreacion']: dateObj });
  }

  const fetchUsersData = () => {
    setUsuarios(AGENTES_IT);
    setInformador();
    setOptions(AGENTES_IT);
    /*execute("GET", `${BACKEND_URL}api/users/getAllUsers`).then((response) => {
      // si no hay usuarios guardados en la base entonces lo recupero de forma remota
      if (response && response.length > 10) {
        setUsuarios(response);
        setInformador();
      }
    })*/
  }

  const fetchOpcionesBloqueado = () => {
    execute("GET", `${BACKEND_URL}${GET_OPCIONES_BLOQUEADO_POR}`).then((response) => {
      // si no hay usuarios guardados en la base entonces lo recupero de forma remota
      if (response && response.length > 0) {
        let newArray = response.map((user) => { return ({ name: user['title'], id: user['_id'] }) });
        setOpcionesBloqueadoPor(newArray);
      }
    })
  }

  const setInformador = () => {
    let { currentEmail, currentName, currentId } = getLogginInfo();

    let copy_asset = ticket;
    copy_asset['creadoPor'] = currentName;
    copy_asset['idCreadoPor'] = currentId;
    setTicket(copy_asset);
    setTicket({ ...ticket, ['creadoPor']: currentName });
    setTicket({ ...ticket, ['idCreadoPor']: currentId });
  }

  function search(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].tipo === nameKey) {
        return myArray[i];
      }
    }
  }

  const handleInputChange = (key, value) => {
    // si cambio el tipo o la prioridad
    if (value['name']) {
      if (value['name'] == 'tipo') {
        setTicket({ ...ticket, ['tipo']: key['label'] });
        setTipo(search(key['label'], tipitos))
      } else {
        setTicket({ ...ticket, ['prioridad']: key['label'] });
        setPrioridad(search(key['label'], prioridades))
      }
    } else {
      // si cambio algun otro
      setTicket({ ...ticket, [key]: value });
    }
  };

  useEffect(() => {
    fetchUsersData();
    setCurrentDate();
    fetchOpcionesBloqueado();
  }, [execute]);
/*
  useEffect(() => {
    let newArray = usuarios.map((user) => { return ({ name: user.displayName, id: user.id }) });
    setOptions(newArray);
  }, [usuarios]);*/

  const handleAsignadoAChange = (nombre, id) => {
    let copy_asset = ticket;
    copy_asset['asignadoA'] = nombre;
    copy_asset['idAsignadoA'] = id;
    setTicket(copy_asset);
    setTicket({ ...ticket, ['asignadoA']: nombre });
  };

  // lsita entera, el que acabo de agregar
  const handleObservadoresChange = (selectedList, selectedItem) => {
    setObservadores(selectedList);
  };

  // lista de posibles bloqueadores
  const handleBloqueadoPorChange = (selectedList, selectedItem) => {
    setBloqueadoPor(selectedList);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data.icon}
      {children}
    </components.SingleValue>
  );

  const Option = (props) => (
    <components.Option {...props} className="country-option">
      {props.data.icon}
      {props.data.label}
    </components.Option>
  );

  const addAsset = () => {
    let copy_asset = ticket;
    // agrego info del usuario para el historico    
    copy_asset['usuario'] = activeAccount["name"];
    copy_asset['usuario_email'] = activeAccount["username"];

    // Si tiene adjuntos
    if (selectedfile && selectedfile.length > 0) {
      execute("POST", `${BACKEND_URL}${POST_ADJUNTOS_PATH}`, selectedfile).then((response) => {
        console.log("Response:: " + JSON.stringify(response))
        if (response) {
          console.log("CANTIDAD DE ADJUNTOS: " + JSON.stringify(Object.values(response["insertedIds"])))
          copy_asset['adjuntos'] = Object.values(response["insertedIds"]);

          if (observadores)
            copy_asset['observadores'] = observadores.map((observador) => { return (observador['id']) });

          if (bloqueadoPor)
            copy_asset['bloqueadoPor'] = bloqueadoPor.map((bloqueador) => { return (bloqueador['id']) });

          setTicket(copy_asset);
          setTicket({ ...ticket, ['adjuntos']: response["insertedIds"] });

          execute("POST", `${BACKEND_URL}${POST_TICKET_PATH}`, copy_asset).then((r) => {
            if (r) {
              addAssetModalSetting();
              console.log("Ticket creado correctament");
            }
          })
        }
      })
      addAssetModalSetting();
    } else {
      
      if (observadores)
        copy_asset['observadores'] = observadores.map((observador) => { return (observador['id']) });

      if (bloqueadoPor)
        copy_asset['bloqueadoPor'] = bloqueadoPor.map((bloqueador) => { return (bloqueador['id']) });

      console.log("Antes de insertartlo");
      execute("POST", `${BACKEND_URL}${POST_TICKET_PATH}`, copy_asset).then((r) => {
        if (r) {
          console.log("Ticket creado correctamente");
        }
        addAssetModalSetting();
        handlePageUpdate();
      })
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl max-h-full">

                <div className="mt-4 text-left sm:mt-0 sm:ml-4 sm:text-left ">

                  {/* Titulo */}
                  <Dialog.Title
                    as="h3"
                    className="mx-auto w-full max-w-[550px] text-lg font-semibold leading-6 text-gray-900 mb-4"
                  >
                    {updateAssetData ? "Editar Asset" : "Agregar Asset"}
                  </Dialog.Title>

                  {/* Inputs */}
                  <form className="group" noValidate>

                    <div className="mx-auto w-full max-w-[550px] bg-white">

                      {/* Tipo */}
                      <div className="mb-4">
                        <label
                          htmlFor="tipo"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          Tipo
                        </label>
                        <Select
                          name="tipo"
                          id="tipo"
                          value={tipo}
                          options={tipitos}
                          onChange={handleInputChange}
                          styles={{
                            singleValue: (base) => ({
                              ...base,
                              display: "flex",
                              alignItems: "center"
                            })
                          }}
                          components={{
                            Option,
                            SingleValue
                          }}
                        />
                      </div>

                      {/* Prioridad */}
                      <div className="mb-4">
                        <label
                          htmlFor="prioridad"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Prioridad</span>

                          <Select
                            name="prioridad"
                            id="prioridad"
                            value={prioridad}
                            options={prioridades}
                            onChange={handleInputChange}
                            styles={{
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center"
                              })
                            }}
                            components={{
                              Option,
                              SingleValue
                            }}
                          />

                        </label>
                      </div>

                      {/* Titulo */}
                      <div className="mb-4">
                        <label
                          htmlFor="title"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Titulo </span>
                          <input
                            type="text"
                            id="title"
                            name="title"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.title}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            required
                          />
                        </label>
                      </div>

                      {/* Descripcion */}
                      <div className="mb-2 min-h-80">
                        <label
                          htmlFor="description"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Descripcion</span>
                          <textarea
                            id="description"
                            name="description"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.description}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            rows={12} cols={40}
                            required
                          />
                        </label>
                      </div>

                      {/* Adjuntos */}
                      <div className="mb-4">
                        <label
                          htmlFor="adjuntos"
                          className="block text-sm font-medium text-gray-900"
                        >
                          <span>Adjuntos</span><br />
                          <InputFile selectedfile={selectedfile} SetSelectedFile={SetSelectedFile} />
                        </label>
                      </div>

                      {/* Informador */}
                      <div className="mb-4">
                        <label
                          htmlFor="creadoPor"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Informador</span>
                          <input
                            type="text"
                            id="creadoPor"
                            name="creadoPor"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.creadoPor}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                          />
                        </label>
                      </div>

                      {/* AsignadoA */}
                      <div className="mb-4">
                        <label
                          htmlFor="asignado"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Asignado a</span>
                          <select
                            name="asignadoA"
                            id="asignadoA"
                            value={ticket['idAsignadoA']}
                            onChange={(e) =>
                              handleAsignadoAChange(e.target.options[e.target.selectedIndex].text, e.target.value)
                            }
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                          >
                            <option disabled selected value> -- Elije una opcion -- </option>
                            {usuarios && usuarios.map((element, index) => {
                              return (
                                <option key={element.id} value={element.id}>{element.name}</option>
                              )
                            })}
                          </select>

                        </label>
                      </div>

                      {/* Observadores */}
                      <div className="mb-4">
                        <label
                          htmlFor="observadores"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Observadores</span>

                          <Multiselect
                            name="observadores"
                            id="observadores"
                            selectionLimit="8"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            closeOnSelect="true"
                            placeholder="Observadores.."
                            options={options} // Options to display in the dropdown
                            displayValue="name" // Property name to display in the dropdown options
                            onSelect={handleObservadoresChange}
                            onRemove={handleObservadoresChange}
                            selectedValues={observadores}
                          />
                        </label>
                      </div>

                      {/* Bloqueado Por */}
                      <div className="mb-4">
                        <label
                          htmlFor="bloqueadoPor"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Bloqueado Por</span>

                          <Multiselect
                            name="bloqueadoPor"
                            id="bloqueadoPor"
                            selectionLimit="8"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            closeOnSelect="true"
                            placeholder="Bloqueado por.."
                            options={opcionesBloqueadoPor} // Options to display in the dropdown
                            displayValue="name" // Property name to display in the dropdown options
                            onSelect={handleBloqueadoPorChange}
                            onRemove={handleBloqueadoPorChange}
                            selectedValues={bloqueadoPor}
                          />
                        </label>
                      </div>

                      {/* Tiempo de finalizacion */}
                      {/*<div className="mb-4 flex-row"> */}
                      <div className="grid gap-4 mb-4 mt-6 sm:grid-cols-4">
                        <label
                          htmlFor="fechaEsperada"
                          className="col-span-2 mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Fecha de Finalizacion </span>
                          <input
                            type="date"
                            id="fechaEsperada"
                            name="fechaEsperada"
                            className="inline p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.fechaEsperada}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                          />
                        </label>

                        <label
                          htmlFor="fechaCreacion"
                          className="col-span-2 mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Fecha de Creacion </span>
                          <input
                            type="text"
                            id="fechaCreacion"
                            name="fechaCreacion"
                            className="inline p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.fechaCreacion}
                            disabled={true}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            required
                          />
                        </label>

                        <label
                          htmlFor="tiempoEstimado"
                          className="col-span-2 mb-2 text-sm font-medium text-gray-900"
                        >
                          <span>Tiempo Estimado (horas)</span>
                          <input
                            type="number"
                            id="tiempoEstimado"
                            name="tiempoEstimado"
                            className="inline p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                            value={ticket.tiempoEstimado}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            required
                          />
                        </label>
                      </div>

                    </div>

                    {/* Botones */}
                    <div className="sm:flex sm:flex-row-reverse sm:px-6 mb-4">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto group-invalid:pointer-events-none group-invalid:opacity-30"
                        onClick={addAsset}
                      >
                        Crear Ticket
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => addAssetModalSetting()}
                        ref={cancelButtonRef}
                      >
                        Cancelar
                      </button>
                    </div>
                  </form>

                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}