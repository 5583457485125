export const BACKEND_URL = "https://stockit.eastus2.cloudapp.azure.com:8443/"
//export const BACKEND_URL = "localhost:5000/"

/* 
export const DeleteButton = require('../images/delete-icon.png')
export const LoadingWheel = require('../images/loading-wheel.gif')
 */

/* TICKETS */
export const MAILS_AGENTES = ['2eb8e8d3-8e8e-4811-bb8d-42f4153272f2', '7d2547d1-ff0e-477a-84a7-4776b497cf1f', 'e5079a50-b200-4f1b-bee3-c6b476562080', '15bc216d-020e-406f-ae07-82150932c33a', '28303369-1961-47b6-af32-dc3563d1110f', 'd060d822-2795-4e21-b936-872bb166be13']
export const ID_AGENTES = ['2eb8e8d3-8e8e-4811-bb8d-42f4153272f2', '7d2547d1-ff0e-477a-84a7-4776b497cf1f', 'e5079a50-b200-4f1b-bee3-c6b476562080', '15bc216d-020e-406f-ae07-82150932c33a', '28303369-1961-47b6-af32-dc3563d1110f', 'd060d822-2795-4e21-b936-872bb166be13']
export const AGENTES_IT = [{id: '2eb8e8d3-8e8e-4811-bb8d-42f4153272f2', name: 'Dámaris Albornoz'}, {id: '7d2547d1-ff0e-477a-84a7-4776b497cf1f', name: 'Carlos Quintero'}, {id: 'e5079a50-b200-4f1b-bee3-c6b476562080', name: 'Federico Berón'}, {id: '15bc216d-020e-406f-ae07-82150932c33a', name: 'Alejo David'}, {id: '28303369-1961-47b6-af32-dc3563d1110f', name: 'Melisa Aranda'}, {id: 'd060d822-2795-4e21-b936-872bb166be13', name: 'Maximiliano Moreira'} ]
export const ESTADOS_TICKETS = {abierto: 'Abierto', enProgreso: 'En Progreso', pendiente: 'Pendiente', bloqueado: 'Bloqueado', solucionado: 'Solucionado', finalizado: 'Cerrado'}
export const COLORES_ESTADOS_TICKETS = {'Abierto': "#17469e", 'En Progreso': "#17469e", 'Pendiente': "#b72842", 'Bloqueado': "#b72842", 'Solucionado': "#b72842", 'Cerrado': "#b72842"}
export const TIPOS_TICKETS = {issue: 'Issue', tarea: 'Tarea', bug: 'Bug'}
export const PRIORIDADES_TICKETS = {baja: 'Baja', media: 'Media', alta: 'Alta'}
export const DEFAULT_TICKET = {
    estado: ESTADOS_TICKETS.abierto ,
    tipo: TIPOS_TICKETS.issue,
    id_generado: "",
    prioridad: PRIORIDADES_TICKETS.baja,
    title: "",
    description: "",
    adjuntos: "",
    asignadoA: "",
    idAsignadoA: "",
    bloqueadoPor: [],
    observadores: [],
    creadoPor: "",
    idCreadoPor: "",
    fechaCreacion: "",
    fechaActualizacion: "",
    fechaResolucion: "",
    solucion: "",
    fechaEsperada: "",
    tiempoTrabajado: 0,
    tiempoEstimado: ""
  }


/* ASSETS */
export const ESTADOS_ASSETS = {asignado: 'Asignado', enReparacion: 'En Reparacion', sinAsignar: 'Sin Asignar', obsoleto: 'Obsoleto', extraviado: 'Extraviado', robado: 'Robado'}
export const TIPOS_ASSETS = {fisico: 'Fisico', digital: 'Digital'}

export const GET_ASSETS_PATH = "api/asset/get"
export const GET_TICKETS_PATH = "api/tickets/getAll"
export const GET_OPCIONES_BLOQUEADO_POR = "api/tickets/getOpcionesBloqueadoPor"
export const UPDATE_TICKETS_PATH = "api/tickets/update"
export const GET_ADJUNTOS = "api/tickets/getAdjuntos/"
export const POST_ADJUNTOS_PATH = "api/tickets/insertAdjuntos"
export const POST_TICKET_PATH = "api/tickets/add"
export const GET_COMENTARIOS = "api/tickets/getComments/"
export const ADD_COMMENTS_PATH = "api/tickets/addComment"
